import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Home from '../modules/Home/Home';
import Post from '../types/Post';

interface Frontmatter {
  frontmatter: Post;
}

interface Props extends PageProps {
  allMarkdownRemark: {
    nodes: Array<Frontmatter>;
    pageInfo: {
      totalCount: number;
    };
  };
}

const IndexPage = ({ data }: PageProps<Props>) => {
  const {
    allMarkdownRemark: {
      nodes: posts,
      pageInfo: { totalCount },
    },
  } = data;

  return (
    <Home
      posts={posts.map((post) => post.frontmatter)}
      currentCategory="ALL POSTS"
      currentPage={1}
      totalPosts={totalCount}
    />
  );
};

export const query = graphql`
  query GetBlogPosts {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { glob: "*", nin: ["events"] }, title: { glob: "*" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 6
    ) {
      ...PostsNodes
    }
  }
`;

export default IndexPage;
