import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../global/Layout';
import BlogPost from '../../global/BlogPost/BlogPost';
import Post, { Event } from '../../types/Post';
import PageContext from '../../contexts/PageContext';
import EventPost from '../../global/BlogPost/EventPost';

interface Props {
  posts: Array<Post>;
  currentCategory: string;
  currentPage: number;
  totalPosts: number;
}

const Home: React.FC<Props> = ({
  posts, currentCategory, currentPage, totalPosts,
}) => {
  const isAllPosts = currentCategory.toLowerCase().includes('all');
  const isEvents = currentCategory.toLowerCase().includes('events');

  return (
    <PageContext.Provider value={{ currentPage: currentCategory }}>
      <Layout>
        <section className="l-grid center s-column-1 m-column-2 l-column-3 s-c-gap-9 s-r-gap-15 m-r-gap-20 m-pl-22 m-pr-8 s-px-3 l-pr-12 s-mb-20 main-center">
          {posts.map((post) => {
            const [firstCategory] = post.categories;

            return isEvents ? (
              <EventPost key={post.permalink} event={post as Event} category="events" />
            ) : (
              <BlogPost
                key={post.permalink}
                post={post}
                category={isAllPosts ? firstCategory : currentCategory}
              />
            );
          })}
        </section>
        {posts.length < totalPosts && (
          <div className="post-load-more">
            <Link
              to={`/all/page/${currentPage + 1}`}
              role="button"
              className="post-load-more__button s-px-10 s-py-4 s-font-sz-14 font-w-semi-bold font-f-montserrat"
            >
              Load more
            </Link>
          </div>
        )}
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  fragment PostsNodes on MarkdownRemarkConnection {
    nodes {
      frontmatter {
        title
        author
        when(formatString: "dddd MMMM Do, YYYY - hh:mm A")
        where
        city
        categories
        permalink
        image
      }
    }
    pageInfo {
      totalCount
    }
  }
`;

export default Home;
