import React from 'react';
import { Link } from 'gatsby';
import { Event } from '../../types/Post';

interface Props {
  event: Event;
  category: string;
}

const EventPost: React.FC<Props> = ({ event }) => (
  <article className="post-container event s-pb-2">
    <Link to={event.permalink}>
      <header className="image-container s-mb-6">
        <img
          className="image"
          src={event.image}
          alt={event.title}
          title={event.title}
          loading="lazy"
        />
      </header>
      <div>
        <time
          className="s-font-sz-14 text-primary font-w-bold s-pl-3 s-pr-4 block"
          dateTime={event.when}
        >
          {event.when}
        </time>
        <h2 className="s-font-sz-16 m-font-sz-24 text-grey font-w-xbold s-pl-3 s-pr-4 s-mb-3">
          {event.title}
        </h2>
        <p className="s-font-sz-14 s-pl-3 s-pr-4 text-lighter-grey">{event.city}</p>
      </div>
    </Link>
  </article>
);

export default EventPost;
