import React from 'react';
import { Link } from 'gatsby';
import Post from '../../types/Post';

interface Props {
  post: Post;
  category: string;
}

const BlogPost: React.FC<Props> = ({ post, category }) => (
  <article className="post-container">
    <Link to={post.permalink}>
      <header className="image-container s-mb-5">
        <img
          className="image"
          src={post.image}
          alt={post.title}
          title={post.title}
          loading="lazy"
        />
      </header>
      <div>
        <h2 className="post-container__title s-font-sz-16 m-font-sz-24 text-grey font-w-xbold s-pl-3 s-pr-4">
          {post.title}
        </h2>
        <p className="s-font-sz-12 s-pl-3 s-pr-4 text-grey">
          By
          {' '}
          {post.author}
          {' '}
          <span className="text-primary font-w-xbold">-</span>
          {' '}
          <span className="font-w-bold capitalize">{category}</span>
        </p>
      </div>
    </Link>
  </article>
);

export default BlogPost;
